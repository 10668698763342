.nav-menu {
  @apply cursor-pointer rounded font-semibold text-gray-700  py-2 px-2 md:mx-2;
}

.nav-menu:hover {
  @apply bg-green-700 text-white;
}

.footer-menu {
  @apply m-4 cursor-pointer text-white;
}
/* 
text-gray-700 rounded hover:bg-gray-900 hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2
*/

/* ACCORDION */
/* Tab content - closed */
.tab-content {
  max-height: 0;
  -webkit-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  transition: max-height 0.35s;
}
/* :checked - resize to full height */
.tab input:checked ~ .tab-content {
  max-height: 100vh;
}
/* Label formatting when open */
.tab input:checked + label {
  /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
  font-size: 1.25rem; /*.text-xl*/
  padding: 1.25rem; /*.p-5*/
  border-left-width: 2px; /*.border-l-2*/
  border-color: #b751e1; /*.border-indigo*/
  background-color: #fbf0ff; /*.bg-gray-100 */
  /*.text-indigo*/
}

/* Icon */
.tab label::after {
  float: right;
  right: 0;
  top: 0;
  display: block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  font-size: 1.25rem;
  text-align: center;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
/* Icon formatting - closed */
.tab input[type="checkbox"] + label::after {
  content: "+";
  font-weight: bold; /*.font-bold*/
  border-width: 1px; /*.border*/
  border-radius: 9999px; /*.rounded-full */
  border-color: #b8c2cc; /*.border-grey*/

  background-color: #b751e1;
  color: #f8fafc; /*.text-grey-lightest*/
}
.tab input[type="radio"] + label::after {
  content: "\25BE";
  font-weight: bold; /*.font-bold*/
  border-width: 1px; /*.border*/
  border-radius: 9999px; /*.rounded-full */
  border-color: #b8c2cc; /*.border-grey*/

  background-color: #b751e1;
  color: #f8fafc; /*.text-grey-lightest*/
}
/* Icon formatting - open */
.tab input[type="checkbox"]:checked + label::after {
  transform: rotate(315deg);
  background-color: #b751e1;
  color: #f8fafc; /*.text-grey-lightest*/
}
.tab input[type="radio"]:checked + label::after {
  transform: rotateX(180deg);
  background-color: #b751e1;
  color: #f8fafc; /*.text-grey-lightest*/
}

.subscription-panel-basic,
.subscription-panel-free,
.subscription-panel-premium {
  border-top-width: 5px !important;
}
.subscription-panel-free {
  border-top-color: #585858 !important;
}

.subscription-panel-basic {
  border-top-color: #b751e1 !important;
}

.subscription-panel-premium {
  border-top-color: #b751e1 !important;
}
