.nav-menu {
  @apply  cursor-pointer rounded font-semibold text-sm text-gray-700 py-2 px-1 md:mx-1;
}

.nav-menu:hover {
  @apply text-custom-purple bg-white text-white;
}

.profile-menu {
  @apply inline-block cursor-pointer  font-semibold text-gray-700  py-2 px-2 md:mx-2;
}
