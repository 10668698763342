@tailwind base;
@tailwind components;
@tailwind utilities;
/* @tailwind preflight; */

@font-face {
  font-family: "Nunito";
  src: url("./assets/nunito/Nunito-Light.ttf");
}

@import "./pages/Home/styles.css";

body {
  font-family: "Nunito", sans-serif;
}

/* GLOBAL INPUT STYLES */

.md-input-main {
  @apply font-sans text-xl w-full;
  width: 100%;
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.md-input-box {
  @apply relative;
  position: relative;
}
.md-input {
  @apply w-full;
  width: 100%;
  outline: none;
  height: 40px;
}
.md-label {
  @apply absolute pointer-events-none block;
  display: block;
  position: absolute;
  pointer-events: none;
  transform-origin: top left;
  transform: translate(0, -40px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.md-label-focus {
  @apply text-green-500;
  color: #006400;
  transform: translate(0, -65px) scale(0.75);
  transform-origin: top left;
}
.md-input-underline {
  border-bottom: 1px solid #006400;
}
.md-input-underline:after {
  @apply absolute left-0 right-0 pointer-events-none;
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
  bottom: -0.05rem;
  content: "";
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: scaleX(0);
  border-bottom: 2px solid #006400;
}
.md-input:focus ~ .md-input-underline:after {
  transform: scaleX(1);
}
.md-input:focus + .md-label,
.md-input:not(:placeholder-shown) + .md-label {
  @apply md-label-focus;
  color: #b751e1;
  transform: translate(0, -65px) scale(0.75);
  transform-origin: top left;
}

.top-bar-container {
  height: 48px;
}

.sidebar-container {
  height: calc(100vh - 48px);
  max-width: 300px;
}

.body-container {
  height: calc(100vh - 48px);
  box-shadow: inset 1px 1px 22px #0003;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
